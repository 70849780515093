import React from 'react';

export default () => (
  <svg
    id="Grupo_368"
    data-name="Grupo 368"
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <path
      id="Trazado_112"
      data-name="Trazado 112"
      d="M12.5,0A12.5,12.5,0,1,0,25,12.5,12.5,12.5,0,0,0,12.5,0Zm0,4.391L19.9,9H5.1ZM19.99,16.369h0a1.339,1.339,0,0,1-1.339,1.339H6.352a1.339,1.339,0,0,1-1.339-1.339V9.3a1.332,1.332,0,0,1,.021-.229L12.1,13.474l.027.014.028.015a.742.742,0,0,0,.154.06l.016,0a.762.762,0,0,0,.174.022h0a.749.749,0,0,0,.174-.022l.016,0a.749.749,0,0,0,.154-.06l.028-.015.027-.014,7.067-4.407a1.331,1.331,0,0,1,.021.229v7.072Z"
      transform="translate(-0.001 0)"
      fill="#f9f9f9"
    />
  </svg>
);
