import React from 'react';

export default ({ className, ...other }) => (
  <svg
    id="Grupo_369"
    data-name="Grupo 369"
    xmlns="http://www.w3.org/2000/svg"
    width="25.175"
    height="25"
    viewBox="0 0 25.175 25"
  >
    <path
      id="Trazado_110"
      data-name="Trazado 110"
      d="M12.588,0A12.5,12.5,0,1,0,25.175,12.5,12.558,12.558,0,0,0,12.588,0Zm3.13,12.94H13.67v7.248H10.636V12.94H9.193V10.378h1.443V8.721A2.835,2.835,0,0,1,13.7,5.68l2.249.009V8.175H14.315a.616.616,0,0,0-.643.7v1.505h2.312Z"
      fill="#f9f9f9"
    />
  </svg>
);
