import React from 'react';

import Footer from './footer';
import Header from './header';
import { headerData, footerData } from './data';
import TemplatesLayout from './templates-layout';
import LpLayout from './lp-layout';

export default function LayoutComponent({ children, location, ...props }) {
  const isWebinar = typeof window !== 'undefined' && location.pathname.replace(/\//g, '') === 'webinar';
  const isTemplates = typeof window !== 'undefined' && location.pathname.replace(/\//g, '') === 'templates';
  const isLp = typeof window !== 'undefined' && (location.pathname.includes('-lp') || location.pathname.includes('privacy-policy') || location.pathname.includes('tos') || location.pathname.includes('manifesting-breakthrough-challenge') || location.pathname.includes('manifesting-breakthrough-wl'));

  if (isWebinar) {
    return (
      <>{children}</>
    );
  }
  if (isTemplates) {
    return (
      <>
        <TemplatesLayout {...props}>
          {children}
        </TemplatesLayout>
      </>
    );
  }
  if (isLp) {
    return (
      <>
        <LpLayout {...props}>
          {children}
        </LpLayout>
      </>
    );
  }
  return (
    <>
      <Header {...headerData} />
      {children}
      <Footer {...footerData} />
    </>
  );
}
