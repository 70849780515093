import React from 'react';

export default () => (
  <svg
    id="email"
    xmlns="http://www.w3.org/2000/svg"
    width="63.631"
    height="63.632"
    viewBox="0 0 63.631 63.632"
  >
    <g id="Grupo_46" data-name="Grupo 46" transform="translate(0)">
      <path
        id="Trazado_112"
        data-name="Trazado 112"
        d="M31.816,0A31.816,31.816,0,1,0,63.632,31.816,31.816,31.816,0,0,0,31.816,0Zm0,11.175,18.827,11.74H12.989ZM50.879,41.663h0a3.409,3.409,0,0,1-3.409,3.409h-31.3a3.409,3.409,0,0,1-3.409-3.409v-18a3.391,3.391,0,0,1,.053-.583L30.8,34.3c.022.014.046.023.068.036l.072.038a1.888,1.888,0,0,0,.392.153l.041.009a1.941,1.941,0,0,0,.444.056h0a1.906,1.906,0,0,0,.444-.056l.041-.009a1.907,1.907,0,0,0,.392-.153l.072-.038c.022-.013.046-.022.068-.036L50.825,23.079a3.386,3.386,0,0,1,.053.583v18Z"
        transform="translate(-0.001)"
        fill="#455972"
      />
    </g>
  </svg>
);
