import React from 'react';
import { useFormInput, useErrors, useSubmitReady } from '@/hooks/useForm';
import Button from 'button';
import axios from 'axios';
import validate from 'utils/validate';
import s from './form.module.scss';

import Spinner from 'shared/spinner';
import { useSubmitState } from '@/hooks/useSubmitState';

export default function emailForm() {
  const email = useFormInput('');
  const emailErrors = useErrors({ email: email.state }, validate);
  const submitReady = useSubmitReady(
    {
      email: email.state,
    },
    validate
  );

  const [disable, submit, submitHandler] = useSubmitState(handleSubmit, "I'd love to", Spinner);


  function handleSubmit() {
    const formEmail = {
      email: email.state.value,
      type: 'newsletter', // A flag for the backend, DON"T REMOVE THIS
    };
    axios('https://us-central1-laurie-anne-king.cloudfunctions.net/handleFormSubmission', { headers: { 'Content-Type': 'application/json' }, method: 'POST', data: formEmail }).then(x => {

      window.location.href = '/thank-you/';
    }).catch(err => {});
  }
  return (
    <form>
      <div className={s.wrapper}>
        <input
          type="text"
          name="email"
          placeholder="EMAIL ADDRESS"
          value={email.state.value}
          {...email.events}
          className={`${emailErrors.email && s.error}`}
        />
        <Button primary className={s.slimButton} disabled={!submitReady || disable} onClick={submitHandler}>{submit}</Button>
      </div>
    </form>
  );
}
