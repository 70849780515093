export const headerData = {
  text: 'LAURIE·ANNE <span>King</span>',
  links: [
    {
      text: 'HOME',
      href: '/',
      hide_desktop: true,
    },
    {
      text: 'COURSES',
      href: '/courses/',
    },
    {
      text: 'ABOUT ME',
      href: '/about-me/',
    },
    {
      text: 'FREE RESOURCES',
      href: '/free-resources/',
    },
    {
      text: 'BLOG',
      href: '/blog/',
    },
    {
      text: 'TESTIMONIALS',
      href: '/testimonials/',
    },
    {
      text: 'CONTACT',
      href: '/contact/',
    },
  ]
};

export const footerData = {
  picturesBanner: {
    headline: '@LAURIEANNEKING',
    images: [
      {
        image: 'misc1',
      },
      {
        image: 'misc2',
      },
      {
        image: 'misc3',
      },
      {
        image: 'misc4',
      },
      {
        image: 'misc5',
      },
      {
        image: 'misc6',
      },
    ],
  },
  navigate: {
    title: 'NAVIGATE',
    links: [
      {
        text: 'COURSES',
        link: '/courses/',
      },
      {
        text: 'ABOUT ME',
        link: '/about-me/',
      },
      {
        text: 'FREE RESOURCES',
        link: '/',
      },
      {
        text: 'BLOG',
        link: '/blog/',
      },
      {
        text: 'TESTIMONIALS',
        link: '/testimonials/',
      },
      {
        text: 'CONTACT',
        link: '/contact/',
      },
      {
        text: 'LOGIN',
        link: '/',
      },
    ],
  },
  social: {
    title: 'WE\'RE SOCIAL',
    icons: [
      {
        icon: 'facebook-round',
        href: 'https://www.facebook.com/laurieannekingcoach/',
      },
      {
        icon: 'instagram-round',
        href: 'https://www.instagram.com/laurieanneking/',
      },
      {
        icon: 'youtube-round',
        href: 'https://www.youtube.com/channel/UClklrdyV26g3GiBPj6ftDjw',
      },
      {
        icon: 'pinterest-round-footer',
        href: 'https://www.pinterest.ca/lakingtv/',
      },
    ],
  }
};
