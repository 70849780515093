import React from 'react';
import Headline from 'headline';
import Icon from 'icon';
import { Link } from 'gatsby';
import s from './social.module.scss';

export default function SocialHelper({ title, icons }) {
  return (
    <div className={s.wrapper}>
      <Headline className={s.title} tertiary h2 center>
        {title}
      </Headline>
      <div className={s.logosRow}>
        {icons.map(({ icon, href }) => (
          <a className={s.socialIcon} href={href}>
            <Icon icon={icon} />
          </a>
        ))}
      </div>
    </div>
  );
}
