import React from 'react';

export default ({ className, ...other }) => (
  <svg
    id="youtube"
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <path
      id="Trazado_117"
      data-name="Trazado 117"
      d="M224.113,216.023l6.931-3.992-6.931-3.992Zm0,0"
      transform="translate(-214.668 -199.531)"
      fill="#f9f9f9"
    />
    <path
      id="Trazado_118"
      data-name="Trazado 118"
      d="M12.5,0A12.5,12.5,0,1,0,25,12.5,12.5,12.5,0,0,0,12.5,0Zm7.811,12.513a20.631,20.631,0,0,1-.322,3.757,1.957,1.957,0,0,1-1.377,1.377,47.51,47.51,0,0,1-6.112.322,45.81,45.81,0,0,1-6.112-.335,1.958,1.958,0,0,1-1.377-1.377A20.54,20.54,0,0,1,4.689,12.5a20.617,20.617,0,0,1,.322-3.757,2,2,0,0,1,1.377-1.39A47.51,47.51,0,0,1,12.5,7.031a45.712,45.712,0,0,1,6.112.335,1.958,1.958,0,0,1,1.377,1.377A19.578,19.578,0,0,1,20.311,12.513Zm0,0"
      fill="#f9f9f9"
    />
  </svg>
);
